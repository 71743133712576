import React, { useEffect } from 'react';
import "./Services.css"


const Services = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div id='Service'>
      Service
    </div>
  )
}

export default Services
